#products-page section{
    padding: 50px;
}

@media (max-width: 400px){
    #products-page section{
        padding: 30px;
    }
    .title{
        font-size: 2.2rem !important;
    }
}

.title{
    font-weight: 500;
    font-size: 3rem;
    color: var(--invett-secondary-color);
}

#products-page img{
    width: inherit;
}