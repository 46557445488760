.contact-icon-container{
    font-size: 2rem;
}

.contact-icon-container i{
    font-size: 3rem;
}

.icon-color{
    color: var(--invett-primary-color);
}