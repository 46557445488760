.text-information{
    font-size: 1.5rem;
    text-align: center;
}

@media (max-width: 600px) {
    .text-information{
        font-size: 1.3rem;
    }
}

@media (max-width: 450px) {
    .text-information{
        font-size: 1.1rem;
    }
}

.exclusive-sale-button{
    text-decoration: none;
    border: 2px solid rgb(255, 103, 30);
    color: white;
    font-weight: 500;
    border-radius: 15px;
    background-color: rgb(255, 103, 30);
}

.exclusive-sale-button:hover{
    color: rgb(255, 103, 30);
    background-color: white;
}

.exclusive-sale-banner {
    width: inherit;
}

.banner-image {
    height: 550px;
    background-image: url("../../public/assets/images/Portada-invett.jpg");
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

@media (max-width: 920px) {
    .banner-image {
        background-size: cover;
    }
}

.banner-content {
    border-radius: 10px;
    padding: 15px;
}

.banner-content p {
    font-size: 1.1rem;
    text-align: justify;
}

.banner-content h1 {
    font-weight: bold;
    color: var(--invett-secondary-color);
}

@media (max-width: 730px) {
    .banner-content {
        background-color: rgba(231, 220, 220, 0.5);
    }
}

@media (max-width: 690px) {
    .banner-content {
        min-width: 50%;
    }
}

@media (max-width: 550px) {
    .banner-content {
        min-width: 60%;
    }
}

@media (max-width: 480px) {
    .banner-content {
        min-width: 70%;
    }
}

@media (max-width: 430px) {
    .banner-content {
        min-width: 80%;
    }
}

@media (max-width: 300px) {
    .banner-content {
        min-width: 85%;
    }
}

.orders {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: var(--invett-primary-color);
}

.orders h3 {
    font-weight: 600;
    font-weight: bolder;
}

.orders p {
    color: white;
    font-weight: bolder;
}

.orders a {
    padding: 15px 30px 15px 30px;
    text-decoration: none;
    color: white;
    font-weight: 500;
    border: 5px solid rgb(35, 35, 35);
    border-radius: 15px;
    background-color: rgb(35, 35, 35);
}

.orders a:hover {
    color: white;
    background-color: var(--invett-primary-color);
}

.service-container {
    padding: 20px;
    border-radius: 15px;
    background-color: ghostwhite;
}

.service-container:hover {
    background-color: rgb(233, 241, 221);
}

.service-container i {
    font-size: 2.5rem;
    color: var(--invett-primary-color);
}

.service-container h3 {
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--invett-secondary-color);
}

.service-container p {
    padding: 15px;
}

.allied-brands {
    padding: 30px;
}

.veterinary-image {
    width: inherit;
}

.engagement {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: var(--invett-primary-color);
    text-align: center;
    font-size: 1.2rem;
}

.box {
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.91);
    box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.91);
}

@media (max-width: 991px) {
    .box {
        max-width: 70% !important;
    }
}

@media (max-width: 850px) {
    .box {
        max-width: 80% !important;
    }
}

@media (max-width: 650px) {
    .box {
        max-width: 90% !important;
    }
}

@media (max-width: 450px) {
    .box {
        max-width: 95% !important;
    }
}

#home-page .img {
    width: inherit;
}

.text-link {
    text-decoration: none;
    color: var(--invett-primary-color);
}

.text-link:hover {
    cursor: pointer;
    color: var(--invett-secondary-color);
}