.banner-container {
    height: 550px;
    background-image: url("../../public/assets/images/aviso-privacidad.jpg");
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -130px;
}

.privacy-text{
    margin-top: -50px;
    text-align: justify;
}