.promotion-image{
    min-width: 290px;
    max-width: 290px;
    min-height: 580px;
    max-height: 580px;
}

@media (max-width: 767px){
    .promotion-image{
        min-width: 350px;
        max-width: 350px;
        min-height: 640px;
        max-height: 640px;
    }
}