/*New code for having a promotions section as a new button located at navbar*/

@media (max-width: 991px) {
    .responsive-menu-button {
        display: block;
    }
}

@media (min-width: 991px) {
    .responsive-menu-button {
        display: none;
    }
}

/*Comment these classes while promotions section is available
@media (max-width: 767px) {
    .responsive-menu-button {
        display: block;
    }
}

@media (min-width: 768px) {
    .responsive-menu-button {
        display: none;
    }
}*/

.responsive-menu-button {
    color: white;
    border: none;
    font-size: 1.05rem;
    border-radius: 2px;
    background-color: var(--invett-secondary-color);
}

.shadow {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding-bottom: 20px !important;
}

.logo-container {
    width: inherit;
    max-width: 280px;
    margin-left: 10px;
    margin-right: 20px;
}

@media (max-width: 475px) {
    .logo-container {
        max-width: 230px;
    }
}

@media (max-width: 315px) {
    .logo-container {
        max-width: 180px;
    }
}

@media (max-width: 260px) {
    .logo-container {
        max-width: 150px;
    }
}

.main-logo {
    width: inherit;
}

.invett-link {
    padding: 5px;
    color: var(--invett-secondary-color) !important;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 10px;
}

.invett-link-active,
.invett-link:hover {
    color: white !important;
    font-weight: 500;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: var(--invett-primary-color);
}