footer {
    color: white;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--invett-secondary-color);
}

footer hr {
    color: gray;
}

footer p {
    margin: 0;
    color: white;
}

.col-sm i {
    font-size: 2.5rem;
    padding: 20px;
    border-radius: 10px;
    text-decoration: none;
}

.col-sm a {
    color: white;
}

.location-icon:hover,
.phone-icon:hover,
.facebook-icon:hover,
.linked-in-icon:hover {
    background-color: rgb(0, 29, 84);
}

.location-icon,
.social-icons {
    color: crimson;
}

.phone-icon,
.clock-icon {
    color: var(--invett-primary-color);
}

.col-sm p {
    padding-top: 10px;
    padding-bottom: 10px;
}

.go-up{
	padding:15px;
    border-radius: 20px;
	background: var(--invett-secondary-color);
	font-size:20px;
	color: white;
	cursor:pointer;
	position: fixed;
	bottom:20px;
	right:20px;
}

.floating-whatsapp{
    padding:15px;
    border-radius: 20px;
	background: var(--invett-secondary-color);
	font-size:20px;
	color: white;
	cursor:pointer;
	position: fixed;
	bottom:20px;
	right:70px;
}