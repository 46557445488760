.coverage-map-image{
    width: inherit;
}

.title{
    color: var(--invett-secondary-color);
}

.circle-icon{
    font-size: 0.6rem;
    color: var(--invett-primary-color);
}

.text{
    font-size: 1.1rem;
}